import Link from 'next/link';
import Image from 'next/image';
import { Box, Button, Container, Typography } from '@mui/material';
import { ErrorMessage, ErrorReason } from '@/utils/types';
type ErrorProps = {
  reason?: ErrorReason;
};
export default function Error({
  reason
}: ErrorProps) {
  const errorMessage = reason ? ErrorMessage[reason] : ErrorMessage['default'];
  return <Box display="flex" flexDirection="column" height="100%" textAlign="center" justifyContent="center" data-sentry-element="Box" data-sentry-component="Error" data-sentry-source-file="index.tsx">
      <Container maxWidth="md" sx={{
      marginTop: {
        xs: 0,
        lg: '2rem'
      }
    }} data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Image src={'/images/backgrounds/errorimg.svg'} alt="404" width={200} height={400} style={{
        width: '100%',
        maxWidth: '500px',
        maxHeight: '500px'
      }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <Typography align="center" variant="h1" mb={4} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Opps!!!
        </Typography>
        <Typography align="center" variant="h4" mb={4} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {errorMessage}
        </Typography>
        <Button color="primary" variant="contained" component={Link} href="/" disableElevation data-sentry-element="Button" data-sentry-source-file="index.tsx">
          Go Back to Home
        </Button>
      </Container>
    </Box>;
}