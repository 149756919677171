export type LensAuth = {
  accessToken: string
  message: string
}

export enum ErrorReason {
  PROFILE_NOT_FOUND = 'profile-not-found',
}

export const ErrorMessage = {
  ['default']: 'This page you are looking for could not be found.',
  [ErrorReason.PROFILE_NOT_FOUND]:
    'The profile you are looking for could not be found.',
}
